#contact {
  width: 100%;
  margin: auto;
}

.contact__container {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  border-top: 5px solid var(--color-green4);

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2rem;
}

.contact__container a {
  margin-top: 0.75rem;
  font-size: 2rem;
  z-index: 13;
  color: var(--color-green4);
}
