* {
  /*reset all elements*/
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

@font-face {
  font-family: "Unbounded-Regular";
  src: local("Unbounded-Regular"),
    url("./fonts/Unbounded/static/Unbounded-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"),
    url("./fonts/Nunito/static/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"),
    url("./fonts/Nunito/static/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Light";
  src: local("Nunito-Light"),
    url("./fonts/Nunito/static/Nunito-Light.ttf") format("truetype");
}


:root {
  /*GENERAL CONTAINER VARIABLES*/
  --container-width-lg: 76%; /*for desktop*/
  --container-width-md: 90%; /*for phones and tablets*/

  /*BORDER RADIUS VARIABLES */
  --border-radius-1: 1.2rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 0.5rem;
  --border-radius-4: 0.2rem;

  /*GENERAL TRANSITION VARIABLE*/
  --transition: all 500ms ease;

  /*COLORS*/
  --color-black: #232222;
  --color-green1: #cad2c5;
  --color-green2: #84a98c;
  --color-green3: #52796f;
  --color-green4: #354f52;
  --color-green4: #2f3e46;

  --color-gray: #f5f5f5;
}

html {
  scroll-behavior: smooth;
  background-color: #FAF9F6;

  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-family: "Unbounded-Regular";
}

a {
  font-family: "Nunito-Regular";
  font-size: 1.3rem;

  color: var(--color-black);
}

p {
  font-family: "Nunito-Light";
  font-size: 1.2rem;

  color: var(--color-black);
}

h2 {
  font-family: "Nunito-Bold";
  font-size: 1.5rem;

  color: var(--color-black);
}

small
{
  font-family: "Nunito-Bold";
  font-size: 1rem;
}

a {
  transition: var(--transition);
}

@media (max-width: 768px) {
  p {
    font-size: 0.9rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  a {
    font-size: 1.0rem;
  }
  
}

a:hover{
  transform: translateY(-5px)
}