.nav__menu {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.nav {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex: 1;
}

.nav__menu li {
  align-items: center;
}

.routes {
  color: #000;
  text-decoration: none;
}

.icon {
  font-size: 2rem;
}

.routes:hover {
  color: #000;
}

.routes::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .icon {
    font-size: 1.5rem;
  }

  .nav__menu {
    flex-direction: column;
    gap: 1vw;
  }
}

.nav__menu a:hover::before {
  transform: scaleX(1);
}

a {
  padding: 0;
  margin: 0;
}