.bubble__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;
  z-index: 10;
  padding-left: 1rem;
  width: 100%; 
  border-left: solid var(--color-green2) 0.5rem;
  box-sizing: border-box; 
}

#content {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

#image {
  width: 4rem;
  height: fit-content;

  border: null solid 5px;
  border-radius: var(--border-radius-3);
  object-fit: contain;
}

#details {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-start;
  text-align: left
}

#date {
  align-items: flex-end;
}

@media (max-width: 768px) {
  #image {
    width: 0rem;
    opacity: 0%;
  }
}
