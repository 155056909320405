header {
  z-index: 9;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.headerText {
  font-size: 10rem;
  white-space: nowrap;
  opacity: 0%;

  background: linear-gradient(
    to right,
    #cad2c5,
    #84a98c,
    #52796f,
    #354f52,
    #2f3e46
  );

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 300%;
  background-position: var(--mousePos);

  animation: text-in 1.5s cubic-bezier(0.97, 0.01, 0.36, 0.99);
  animation-delay: 0.2s;
  animation-fill-mode: forwards;


}

@media (max-width: 1024px) {
  .headerText {
    font-size: 7rem;
  }
}

@media (max-width: 768px) {
  .headerText {
    font-size: 4rem;
  }
}

.nav__container {
  opacity: 0%;
  width: 100%;
  margin-bottom: 2rem;

  animation: text-in 1.5s cubic-bezier(0.97, 0.01, 0.36, 0.99);
  animation-delay: 0.4s;
  animation-fill-mode: forwards;

}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  gap: 1rem;

}

@keyframes text-in {
  0% {
    transform: translateY(2.5rem);
    opacity: 0%;
  }

  100% {
    transform: translateY(0rem);
    opacity: 100%;
  }
}
