.nav__menu {
  justify-content: center;
  display: flex;

  gap: 3rem;
  
}

.route_container{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.routes {
  position: relative;
  color: #000;
  text-decoration: none;
}

.routes:hover {
  color: #000;
}

.routes::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}


@media (max-width: 768px) {
  .nav__menu {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  a{
    font-size: 0.8rem;
  }
}

.nav__menu a:hover::before {
  transform: scaleX(1);
}

a{
  font-size: 1rem;
}

p {
  margin: 0;
  padding: 0;
}