#about {
  width: 100%;

  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 

  margin-top: 5%;
}

.about__container {
  width: 70%;

  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  gap: 1.5rem;
}

.contents {
  display: flex;
  flex-direction: column;
  gap: 2.0rem;

  margin-bottom: 10%;

}

#headshot {
  width: 100%;
  border-radius: var(--border-radius-2);
  opacity: 70%;
}

.contents h3 {
  width: 40%;
  color: var(--color-green4);
}

@media (max-width: 768px) {
  #headshot {
    width: 100%;
    height: 50vh;
    border-radius: var(--border-radius-2);
    opacity: 70%;
    object-fit: cover;
  }
}

