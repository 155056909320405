.image__container {
  z-index: 12;
  position: relative;

  border-radius: var(--border-radius-3);

  display: flex;
  flex-direction: column;
  gap: 0.2rem
}

.portfolioImages {
  z-index: 12;
  position: relative;

  opacity: 100%;
  width: 100%;
  height: 35vh;

  object-fit: cover;
  vertical-align: middle;

  border-radius: var(--border-radius-3);
  transition: var(--transition);
}

.portfolioImages:hover{
  opacity: 70%;
}

.title_line{
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  width: 100%; 
}

.title_line a{
  margin-left: 0.75rem;
  font-size: 3.5vh;
  display: inline-block;
}

p {
  margin-top: 5px;
}
