#work {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.work__container {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.about__work {
  display: grid;
  grid-gap: 1.5rem;
}

@media (max-width: 768px) {
  .about__work {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1024px) {
  .about__work {
    grid-template-columns: repeat(2, 1fr); 
  }
}

h1 {
  margin-top: 0.5rem;
  color: var(--color-green3);
}
